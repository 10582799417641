import { render, staticRenderFns } from "./TheHomeAddSpace.vue?vue&type=template&id=724c94c7&scoped=true&"
import script from "./TheHomeAddSpace.vue?vue&type=script&lang=js&"
export * from "./TheHomeAddSpace.vue?vue&type=script&lang=js&"
import style0 from "./TheHomeAddSpace.vue?vue&type=style&index=0&id=724c94c7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724c94c7",
  null
  
)

export default component.exports